<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["scores"],
  mounted() {
    let labels = [];
    let data = this.scores;

    for (let i = 0; i < data.length; i++) {
      let trueNumber = i + 1
      labels.push("Ćwiczenie " + trueNumber)
    }

    this.renderChart(
      {
        labels: labels,
        datasets: [
          {
            lineTension: 0,
            label: "Wynik testu (w %)",
            data: data,
            backgroundColor: "transparent",
            borderColor: "#833ab4",
            pointBackgroundColor: "#833ab4"
          },
        ],
      },
      {
        type: "line",
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: "Twój postęp",
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false, //this will remove all the x-axis grid lines
            },
          ],
        },
      }
    );
  },
};
</script>