<template>
  <div>
    <v-card
      class="success-card"
      dark
      v-if="scores[scores.length - 1] - average > 0"
    >
      <v-card-title>{{ scores[scores.length - 1] - average }}%</v-card-title>
      <v-card-subtitle
        >Twój ostatni wynik jest o tyle % lepszy od średniej.</v-card-subtitle
      >
    </v-card>

    <v-card class="danger-card" dark v-else>
      <v-card-title
        >{{ Math.abs(scores[scores.length - 1] - average) }}%</v-card-title
      >
      <v-card-subtitle
        >Twój ostatni wynik jest o tyle % gorszy od średniej.</v-card-subtitle
      >
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Twój średni wynik</v-card-title>
      <v-card-subtitle
        >(średnia wyników ze wszystkich ćwiczeń, które kiedykolwiek
        wykonałeś/aś)</v-card-subtitle
      >

      <v-card-text>
        <v-card class="color-card" elevation="0" dark>
          <v-card-title>{{ average }}%</v-card-title>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Podsumowanie</v-card-title>
      <v-card-subtitle
        >(kolejne wyniki ze wszystkich wykonanych ćwiczeń (w %) przedstawione na
        wykresie w kolejności chronologicznej)</v-card-subtitle
      >
      <v-card-text>
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text>
            <LineChart :scores="scores" />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LineChart from "../components/LineChart.vue";
export default {
  components: { LineChart },
  computed: {
    scores() {
      return this.$store.state.scores;
    },
    weeks() {
      return this.$store.state.weeks;
    },
  },
  mounted() {
    if (this.weeks == []) {
      this.$router.replace({ name: "Dashboard" });
    } else {
      setTimeout(() => {
        this.value = this.scores;
        if (this.scores.length < 2) {
          this.$router.replace({ name: "NoStats" });
        }
      }, 10);

      let sum = 0;
      let elmt = this.scores;
      for (let i = 0; i < elmt.length; i++) {
        sum += parseInt(elmt[i], 10); //don't forget to add the base
      }

      var avg = sum / elmt.length;
      this.average = Math.round(avg);
    }
  },
  data: () => ({
    width: 2,
    average: 0,
    radius: 2,
    padding: 8,
    lineCap: "round",
    gradient: ["#1feaea", "#ffd200", "#f72047"],
    value: [0],
    gradientDirection: "top",
    fill: false,
    type: "trend",
    autoLineWidth: false,
  }),
};
</script>

<style>
.color-card {
  background: linear-gradient(
    90deg,
    rgba(72, 0, 254, 1) 0%,
    rgba(131, 58, 180, 1) 100%
  );
  color: white;
}

.success-card {
  background: linear-gradient(90deg, rgb(0, 175, 44) 0%, rgb(221, 188, 0) 100%);
  color: white;
}

.danger-card {
  background: linear-gradient(90deg, rgb(196, 46, 0) 0%, rgb(221, 188, 0) 100%);
  color: white;
}
</style>
